// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import { dispatch } from "../index";

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  entriesList: [],
};

const slice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setEntriesList: (state, action) => {
      const entriesList = action.payload.entries; 
      state.entriesList = entriesList;
    }, 
    hasError(state, action) {
        state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function addEntriesToList(data, action) {
  return async () => {
    try {    
      dispatch(slice.actions.setEntriesList(data,action));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
