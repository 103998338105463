import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";

import axios from "axios";
import Hotjar from "@hotjar/browser";

// third-party
import firebase from "firebase/compat/app";
import { getFirestore } from "firebase/firestore";
import "firebase/compat/auth";
import { CancelToken } from "axios"; // Import CancelToken

// action - state management
import { LOGIN, LOGOUT } from "store/actions";
import accountReducer from "store/accountReducer";

// project imports
import Loader from "ui-component/Loader";
import { FIREBASE_API } from "config";
import { API_URL } from "config";

// firebase initialize
// if (!firebase.apps.length) {
//     firebase.initializeApp(FIREBASE_API);
// }
var dbi = null;
if (!firebase.apps.length) {
  const app = firebase.initializeApp(FIREBASE_API);
  dbi = getFirestore(app);
}

// const
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const siteId = 3690514;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);

export const db = dbi;

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);
export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          var role = 1;
          var idToken = "";
          var usernics = null;
          var userData = null;
          var lastNIC = null;
          var token = null;
          try {
            idToken = await firebase
              .auth()
              .currentUser.getIdToken(/* forceRefresh */ true);
            const idTokenResult = await firebase
              .auth()
              .currentUser.getIdTokenResult();
            const isAdmin = !!idTokenResult.claims.admin;
            try {
              token = await getPrivateToken(user);
            } catch (error) {
              console.log("Fail to read private token");
            }
            try {
              usernics = await getUserNics(token);
            } catch (error) {
              console.log("Fail to read nics");
            }
            try {
              userData = await getUserPersonalData(token, user);
            } catch (error) {
              console.log("Fail to read userdata");
            }
            try {
              lastNIC = await getLastNIC(token, user.uid);
            } catch (error) {
              console.log("Fail to read userdata");
            }
            let isLogg = false;
            if (!user.isAnonymous) {
              isLogg = true;
            }
            const userId = user.uid;
            const firstName = user.displayName || "Usuario nuevo";

            Hotjar.identify(userId, {
              name: firstName,
              email: user.email,
              nics: usernics,
            });

            dispatch({
              type: LOGIN,
              payload: {
                isLoggedIn: isLogg,
                user: {
                  id: user.uid,
                  email: user.email,
                  name: user.displayName || "Usuario nuevo",
                  picurl: user.photoURL,
                  role: role,
                  sessionToken: idToken,
                  privateToken: token,
                  isAdmin: isAdmin,
                  userNics: usernics,
                  userData: userData,
                  lastNIC: lastNIC,
                },
              },
            });
          } catch (error) {
            // console.error(error);
          }
        } else {
          console.log("else");
          firebase
            .auth()
            .signInAnonymously()
            .then(() => {
              // console.log(firebase.auth().currentUser);
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log(error);
              // ...
            });
          dispatch({
            type: LOGOUT,
          });
        }
      });
    };
    fetchData();
  }, [dispatch]);

  const getUserNics = async (token) => {
    const url = API_URL + "user_profile/readNICS";
    const source = CancelToken.source();

    const headers = {
      Authorization: "Bearer " + token,
      Accept: "*/*",
      "Content-Type": "application/json",
    };
    return new Promise((resolve, reject) => {
      axios
        .get(url, { headers: headers, cancelToken: source.token })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          console.error(error);
          reject(false);
        });
    });
  };

  const getUserPersonalData = async (token, user) => {
    const url = API_URL + "users_data/read";
    const source = CancelToken.source();

    const headers = {
      Authorization: "Bearer " + token,
      Accept: "*/*",
      "Content-Type": "application/json",
    };
    // return new Promise((resolve, reject) => {
    //   axios
    //     .get(url, { headers: headers, cancelToken: source.token })
    //     .then((response) => {
    //       if (response.data.data[0]) {
    //         localStorage.setItem(
    //           "e",
    //           response.data.data[0].email_1 || user.email
    //         );
    //         localStorage.setItem("nd", response.data.data[0].doc_number);
    //         localStorage.setItem("p", response.data.data[0].phone);
    //         localStorage.setItem("t", response.data.data[0].doc_type);
    //         localStorage.setItem("unicom", "CAESS");
    //         localStorage.setItem("nby", "1");
    //       } else {
    //         localStorage.setItem("e", user.email);
    //       }

    //       resolve(response.data.data);
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //       reject(false);
    //     });
    // });

    return new Promise((resolve, reject) => {
      axios
        .get(url, { headers: headers, cancelToken: source.token })
        .then((response) => {
          const data = response.data.data[0];

          if (data) {
            localStorage.setItem("e", data.email_1 || user.email);
            localStorage.setItem("nd", data.doc_number || "");
            localStorage.setItem("p", data.phone || "");
            localStorage.setItem("t", data.doc_type || "");
            localStorage.setItem("unicom", "CAESS");
            localStorage.setItem("nby", "1");
          } else {
            localStorage.setItem("e", user && user.email ? user.email : "");
          }

          resolve(data || []); // Resuelve con un arreglo vacío si no hay datos
        })
        .catch((error) => {
          console.error(error);
          reject(false);
        });
    });
  };

  const getLastNIC = async (token, uid) => {
    const url = API_URL + "user_cache/getLastNIC/" + uid + "/web";
    const source = CancelToken.source();

    const headers = {
      Authorization: "Bearer " + token,
      Accept: "*/*",
      "Content-Type": "application/json",
    };
    return new Promise((resolve, reject) => {
      axios
        .get(url, { headers: headers, cancelToken: source.token })
        .then((response) => {
          // console.log(response.data.record.last_nic);
          try {
            resolve(response.data.record.last_nic);
          } catch (error) {
            resolve([]);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(false);
        });
    });
  };

  const getPrivateToken = (user) => {
    let url = "";
    console.log("GETTING PRIVATE TOKEN FOR");
    if (user.email) {
      url = API_URL + "auth/register";
    } else {
      url = API_URL + "auth/registerAnonymous";
    }
    console.log(url);
    const data = {
      email: user.email,
      firebase_uuid: user.uid,
      last_name: "n/d",
      name: user.displayName || "Usuario nuevo",
      password: user.uid,
      phone_language: "es",
      provider: "web",
      push_tok: "n/d",
      pic_url: user.picurl,
    };
    console.log("payload", data);
    return new Promise((resolve, reject) => {
      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          console.log(response.data.auth_token);
          resolve(response.data.auth_token);
        })
        .catch((error) => {
          console.error(error);
          console.error("FAIL TO SET PRIVATE TOKEN");
          reject(false);
        });
    });
  };

  const firebaseEmailPasswordSignIn = (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const firebaseGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const firebaseFacebookSignIn = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    // provider.addScope('pages_manage_metadata');
    let FBtoken = "";
    const signin = firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var token = result.credential.accessToken;
        var uID = result.additionalUserInfo.profile.id;
        // console.log(token);
        FBtoken = token;
        localStorage.setItem("fbauth", FBtoken);
        // localStorage.setItem("fbauth_all",JSON.stringify(result));
        localStorage.setItem("fbauthid", uID);
        return FBtoken;
      });
    return signin;
    // return result;
  };

  const firebaseRegister = async (email, password) =>
    firebase.auth().createUserWithEmailAndPassword(email, password);

  const logout = () => firebase.auth().signOut();

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const updateProfile = () => {};
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseRegister,
        firebaseEmailPasswordSignIn,
        login: () => {},
        firebaseGoogleSignIn,
        firebaseFacebookSignIn,
        logout,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node,
};

export default FirebaseContext;
