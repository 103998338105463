import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";

// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
// const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));

const InitialView = Loadable(lazy(() => import("views/home")));

const VersionesView = Loadable(
  lazy(() => import("views/admin/versiones_tablets"))
);
const OficinasComerciales = Loadable(
  lazy(() => import("views/admin/oficinas"))
);
const DescargasAESAdmin = Loadable(lazy(() => import("views/admin/descargas")));
const MetodosPagoAdmin = Loadable(
  lazy(() => import("views/admin/payment_gateways"))
);
const NoticiasAESAdmin = Loadable(lazy(() => import("views/admin/noticias")));
const FELAdmin = Loadable(lazy(() => import("views/admin/feladmin")));
const FELContactsAdmin = Loadable(lazy(() => import("views/admin/felcontactsadmin")));
// widget routing
// const WidgetStatistics = Loadable(lazy(() => import('views/widget/Statistics')));

// ==============================|| MAIN ROUTING ||============================== //

const DashboardRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/versiones-admin",
      element: <VersionesView />,
    },

    {
      path: "/agencias-puntos-de-pago-aes-admin",
      element: <OficinasComerciales />,
    },
    ,
    {
      path: "/descargas-admin",
      element: <DescargasAESAdmin />,
    },
    {
      path: "/metodos-pago",
      element: <MetodosPagoAdmin />,
    },
    {
      path: "/noticias-aes-admin",
      element: <NoticiasAESAdmin />,
    },
    {
      path: "/documentos-fel",
      element: <FELAdmin />,
    },
    {
      path: "/contactos-fel",
      element: <FELContactsAdmin />,
    },
    
  ],
};

export default DashboardRoutes;
