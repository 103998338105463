import widget from './widget';
import application from './application';
import dashboard_admin from './dashboard-admin'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [
        widget,  
        application,
        dashboard_admin
        ],
};

export default menuItems;
