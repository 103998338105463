// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import snackbarReducer from './slices/snackbar';

import userReducer from './slices/user';
import cartReducer from './slices/cart';
// import kanbanReducer from './slices/kanban';
import menuReducer from './slices/menu';

import homeReducer from './slices/home';
import placesReducer from "./slices/places"


// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'aes-'
        },
        cartReducer
    ),
    user: userReducer,
    menu: menuReducer,
    home: homeReducer,    
    places: placesReducer,
});

export default reducer;
