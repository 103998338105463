// third-party
import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';

// assets
import { IconChartArcs, IconClipboardList, IconChartInfographic,IconBuildingSkyscraper,IconInfoCircle,IconSearch,IconBulbOff, IconCalendarStats, IconAlertTriangle } from '@tabler/icons';
// import { Icon } from 'react-icons';

// constant
const icons = {
    IconChartArcs,
    IconClipboardList,
    IconChartInfographic,
    IconBuildingSkyscraper,
    IconInfoCircle,
    IconSearch,
    IconBulbOff,
    IconCalendarStats,
    IconAlertTriangle
};

// ==============================|| WIDGET MENU ITEMS ||============================== //

const widget = {
    id: 'dash-admin',
    title: <FormattedMessage id="dash-admin" />,
    icon: icons.IconBuildingSkyscraper,
    type: 'group',
    children: [
        {
            id: 'oficom-admin',
            title: <FormattedMessage id="oficom-admin" />,
            type: 'item',
            url: 'agencias-puntos-de-pago-aes-admin',            
        },
        {
            id: 'noticias-admin',
            title: <FormattedMessage id="noticias-admin" />,
            type: 'item',
            url: '/noticias-aes-admin',
            // icon: icons.IconInfoCircle
        }
        ,
        {
            id: 'descargas-admin',
            title: <FormattedMessage id="descargas-admin" />,
            type: 'item',
            url: 'descargas-admin',
            // icon: icons.IconChartInfographic
        },
        {
            id: 'versiones-admin',
            title: <FormattedMessage id="versiones-admin" />,
            type: 'item',
            url: 'versiones-admin',
            // icon: icons.IconChartInfographic
        }
        ,
        {
            id: 'metodos-pago',
            title: <FormattedMessage id="metodos-pago" />,
            type: 'item',
            url: 'metodos-pago',
            // icon: icons.IconChartInfographic
        },{
            id: 'documentos-fel',
            title: <FormattedMessage id="documentos-fel" />,
            type: 'item',
            url: 'documentos-fel',
            // icon: icons.IconChartInfographic
        },
        {
            id: 'contactos-fel',
            title: <FormattedMessage id="contactos-fel" />,
            type: 'item',
            url: 'contactos-fel',
            // icon: icons.IconChartInfographic
        }
    ]
};

export default widget;
