import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// routes
import MainLayout from 'layout/MainLayout';

import MainRoutes from './MainRoutes';
import FreeRoutes from './FreeRoutes';
import DashboardRoutes from './DashboardRoutes';

import LoginRoutes from './LoginRoutes';
// import AuthenticationRoutes from './AuthenticationRoutes';
import Loadable from 'ui-component/Loadable';
import useAuth from 'hooks/useAuth';


// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));


// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { user } = useAuth();
    const isAdmin = user?.isAdmin ?? false;
    let routes = [FreeRoutes, LoginRoutes, MainRoutes];

    if (isAdmin) {
        routes.unshift(DashboardRoutes);
    }

    return useRoutes(routes);
}
